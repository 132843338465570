import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App);

// 公共组件
import CommonHeader from '@/components/common-header'
import CommonSidebar from '@/components/common-sidebar'
import CommonLayout from '@/components/common-layout'
import CommonTable from '@/components/common-table'
import CommonPagination from '@/components/common-pagination'
import CommonSelect from '@/components/common-select'
import CommonUpload from '@/components/common-upload'
import CommonDatePicker from '@/components/common-date-picker'
import CommonDownload from '@/components/common-download'
import CommonPicturePreview from '@/components/common-picture-preview'
import tcTextScroll from '@/components/tc-text-scroll'
import tcTree from '@/components/tc-tree'
import tcPopup from '@/components/tc-popup'
import CommonRadio from '@/components/common-radio'

app.component("CommonHeader", CommonHeader)
app.component("CommonSidebar", CommonSidebar)
app.component("CommonLayout", CommonLayout)
app.component("tcTree", tcTree)
app.component("CommonTable", CommonTable)
app.component("CommonPagination", CommonPagination)
app.component("CommonSelect", CommonSelect)
app.component("CommonUpload", CommonUpload)
app.component("tcPopup", tcPopup)
app.component("CommonDatePicker", CommonDatePicker)
app.component("CommonDownload", CommonDownload)
app.component("tcTextScroll", tcTextScroll)
app.component("CommonPicturePreview", CommonPicturePreview)
app.component("CommonRadio", CommonRadio);

// 公共样式
import '@/assets/styles/common.scss'

// 路由守卫
import '@/router/permission'

// 自定义消息弹窗
import TcMessage from '@/utils/common/tc-message'
app.config.globalProperties.$TcMsg = TcMessage

// 引入a标签下载方法
import downloadFile from './utils/common/download-file';
app.config.globalProperties.$downloadFile = downloadFile

// 引入文件下载方法
import exportFile from './utils/common/export-file';
app.config.globalProperties.$exportFile = exportFile


// 引入文本框字符串转数组方法
import str2arr from './utils/common/strtoarr'
app.config.globalProperties.$str2arr = str2arr

// 引入范围计算
import calcRange from './utils/common/calc-range'
app.config.globalProperties.$calcRange = calcRange

// 修改element语言
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
app.use(ElementPlus, {
    locale: zhCn,
})

// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}
// 获取浏览器标题
const clientTitle = localStorage.getItem('systemBasicList') ? 
    JSON.parse(localStorage.getItem('systemBasicList')).find(item => item.name === 'client_title').value : '';
    // console.log(clientTitle,'clientTitle');
    document.title = clientTitle;

app.use(store).use(router).mount('#app')

// 非开发环境不使用打印语句
if (process.env.NODE_ENV !== 'development') { 
    console.log = function () {}
}